<template>
    <main>
        <div class="row mt-2">
            <div class="col-lg-6">
                <div class="card shadow-none">
                    <div class="card-header">VISION DE CERCA</div>
                    <div class="card-body">
                        <div class="form-group">
                            <label class="font-weight-bolder">OJO IZQUIERO</label>
                            <div class="row d-flex ">
                                <div class="col-lg-5 px-0">
                                    <input type="number" class="form-control form-control-sm" v-model="vci1"
                                    :class="{ 'is-invalid': $v.vci1.$error && $v.vci1.$dirty }">
                                </div>
                                <div class="col-lg-2 d-flex align-items-center justify-content-center">
                                    <span>/</span>
                                </div>
                                <div class="col-lg-5 px-0">
                                    <input type="number" class="form-control form-control-sm" v-model="vci2"
                                    :class="{ 'is-invalid': $v.vci2.$error && $v.vci2.$dirty }">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bolder">OJO DERECHO</label>
                            <div class="row d-flex ">
                                <div class="col-lg-5 px-0">
                                    <input type="number" class="form-control form-control-sm" v-model="vcd1"
                                    :class="{ 'is-invalid': $v.vcd1.$error && $v.vcd1.$dirty }">
                                </div>
                                <div class="col-lg-2 d-flex align-items-center justify-content-center">
                                    <span>/</span>
                                </div>
                                <div class="col-lg-5 px-0">
                                    <input type="number" class="form-control form-control-sm" v-model="vcd2"
                                    :class="{ 'is-invalid': $v.vcd2.$error && $v.vcd2.$dirty }">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card shadow-none">
                    <div class="card-header">VISION DE LEJOS</div>
                    <div class="card-body">
                        <div class="form-group">
                            <label class="font-weight-bolder">OJO IZQUIERO</label>
                            <div class="row d-flex ">
                                <div class="col-lg-5 px-0">
                                    <input type="number" class="form-control form-control-sm" v-model="vli1"
                                    :class="{ 'is-invalid': $v.vli1.$error && $v.vli1.$dirty }">
                                </div>
                                <div class="col-lg-2 d-flex align-items-center justify-content-center" >
                                    <span>/</span>
                                </div>
                                <div class="col-lg-5 px-0">
                                    <input type="number" class="form-control form-control-sm" v-model="vli2"
                                    :class="{ 'is-invalid': $v.vli2.$error && $v.vli2.$dirty }">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bolder">OJO DERECHO</label>
                            <div class="row d-flex ">
                                <div class="col-lg-5 px-0">
                                    <input type="number" class="form-control form-control-sm" v-model="vld1"
                                    :class="{ 'is-invalid': $v.vld1.$error && $v.vld1.$dirty }">
                                </div>
                                <div class="col-lg-2 d-flex align-items-center justify-content-center">
                                    <span>/</span>
                                </div>
                                <div class="col-lg-5 px-0">
                                    <input type="number" class="form-control form-control-sm" v-model="vld2"
                                    :class="{ 'is-invalid': $v.vld2.$error && $v.vld2.$dirty }">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mt-2">
                <div class="form-group">
                    <label class="font-weight-bolder">USA LENTES</label>
                    <select class="form-control" v-model="lentes"
                        :class="{ 'is-invalid': $v.lentes.$error && $v.lentes.$dirty }">
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 mt-2">
                <div class="form-group">
                    <label class="font-weight-bolder">OBSERVACIONES</label>
                    <textarea type="text" rows="3" v-model="observaciones" class="form-control"
                    ></textarea>
                </div>
            </div>
        </div>
        <!-- <button class="btn btn-success" @click="save()"><i class="fa fa-save fa-fw"></i>Guardar</button> -->

    </main>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import agudezaVisualService from "../../../../services/agudezaVisualService";
import { isEmpty } from 'lodash';
import Toast from "@/components/common/utilities/toast";

export default {
    props: ['idHistoria', 'finalizada'],
    data() {
        return {
            id_historia: '',
            id_agudeza_visual: '',
            vc_ojo_izquierdo: '',
            vc_ojo_derecho: '',
            vl_ojo_derecho: '',
            vl_ojo_izquierdo: '',
            lentes: '',
            observaciones: '',
            vld1:'',
            vld2:'',
            vli1:'',
            vli2:'',
            vci1:'',
            vci2:'',
            vcd1:'',
            vcd2:'',
        }
    },
    validations() {
        return {
            vld1: { required },
            vld2: { required },
            vli1: { required },
            vli2: { required },
            vci1: { required },
            vci2: { required },
            vcd1: { required },
            vcd2: { required },
            lentes: { required }
        }
    },
    methods: {
        async save() {
            try {

                this.$v.$touch();

                if (this.$v.$invalid) {
                    return
                }

                let agudeza_visual = {
                    id_historia: this.idHistoria,
                    id_agudeza_visual: this.id_agudeza_visual,
                    vc_ojo_izquierdo: `${this.vci1}/${this.vci2}`,
                    vc_ojo_derecho: `${this.vcd1}/${this.vcd2}`,
                    vl_ojo_izquierdo: `${this.vli1}/${this.vli2}`,
                    vl_ojo_derecho: `${this.vld1}/${this.vld2}`,
                    lentes: this.lentes,
                    observaciones: this.observaciones,
                }
                this.LoaderSpinnerShow();

                if (this.id_agudeza_visual !== '') {
                    await agudezaVisualService.update(agudeza_visual);
                } else {
                    await agudezaVisualService.store(agudeza_visual);
                    const response = await agudezaVisualService.showByHc(this.idHistoria);
                    this.id_agudeza_visual = response.data.id;
                }

                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'error',
                    title: 'Ha ocurrido un error al procesar la solicitud'
                });
            }

        },

    },
    watch: {
        finalizada: async function (value) {

            if (value === 'N') {
                const response = await agudezaVisualService.showByHc(this.idHistoria);
                if (!isEmpty(response.data)) {
                    const vc_ojo_izquierdo = response.data.vc_ojo_izquierdo.split('/');
                    const vc_ojo_derecho = response.data.vc_ojo_derecho.split('/');
                    const vl_ojo_izquierdo = response.data.vl_ojo_izquierdo.split('/');
                    const vl_ojo_derecho = response.data.vl_ojo_derecho.split('/');

                    const dato1 = vc_ojo_izquierdo[0];
                    const dato2 = vc_ojo_izquierdo[1];

                    const dato3 = vc_ojo_derecho[0];
                    const dato4 = vc_ojo_derecho[1];

                    const dato5 = vl_ojo_izquierdo[0];
                    const dato6 = vl_ojo_izquierdo[1];

                    const dato7 = vl_ojo_derecho[0];
                    const dato8 = vl_ojo_derecho[1];

                    this.vci1 = dato1;
                    this.vci2 = dato2;

                    this.vcd1=dato3;
                    this.vcd2=dato4;
                   
                    this.vli1=dato5;
                    this.vli2=dato6;

                    this.vld1=dato7;
                    this.vld2=dato8;

                    this.lentes = response.data.lentes;
                    this.observaciones = response.data.observaciones;
                    this.id_agudeza_visual = response.data.id;
                }

            }
        }
    },
    async created() {
        if (this.finalizada === 'N') {
            const response = await agudezaVisualService.showByHc(this.idHistoria);
            if (!isEmpty(response.data)) {
                const vc_ojo_izquierdo = response.data.vc_ojo_izquierdo.split('/');
                    const vc_ojo_derecho = response.data.vc_ojo_derecho.split('/');
                    const vl_ojo_izquierdo = response.data.vl_ojo_izquierdo.split('/');
                    const vl_ojo_derecho = response.data.vl_ojo_derecho.split('/');

                    const dato1 = vc_ojo_izquierdo[0];
                    const dato2 = vc_ojo_izquierdo[1];

                    const dato3 = vc_ojo_derecho[0];
                    const dato4 = vc_ojo_derecho[1];

                    const dato5 = vl_ojo_izquierdo[0];
                    const dato6 = vl_ojo_izquierdo[1];

                    const dato7 = vl_ojo_derecho[0];
                    const dato8 = vl_ojo_derecho[1];

                    this.vci1 = dato1;
                    this.vci2 = dato2;

                    this.vcd1=dato3;
                    this.vcd2=dato4;
                   
                    this.vli1=dato5;
                    this.vli2=dato6;

                    this.vld1=dato7;
                    this.vld2=dato8;

                this.lentes = response.data.lentes;
                this.observaciones = response.data.observaciones;
                this.id_agudeza_visual = response.data.id;
            }
        }
    }

}
</script>
