<template>
  <main>
    <h6 class="text-info">Riesgos Generales</h6>
    <table class="table table-striped table-sm table-bordered font-size-12">
      <tr>
        <th>Rango</th>
        <th>Condiciones perinatales y posnatales</th>
        <th>Respuesta</th>
      </tr>
      <tr v-for="item in data_riesgos" :key="item.id">
        <td>
          {{ item.riesgo_general_vale.edad_inicio }} {{ item.riesgo_general_vale.tipo_edad_inicio }} a
          {{ item.riesgo_general_vale.edad_final }} {{ item.riesgo_general_vale.tipo_edad_final }}
        </td>
        <td>{{ item.riesgo_general_vale.condiciones_perinatales_posnatales }}</td>
        <td>
          {{item.respuesta}}
        </td>
      </tr>
    </table>
  </main>
</template>
<script>
export default {
  props: ["idHistoria",'data_riesgos'],
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>