<template>
  <main ref="resumen" id="resumen">
    <div class="card mt-2 shadow-none" v-if="isLoading">
      <div class="card-body">
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="false" />
          <content-placeholders-text :lines="3" />
        </content-placeholders>
      </div>
    </div>
    <div v-else>
      <div class="py-2">
        <motivoConsultaShow :idHistoria="idHistoria" :data="resumen.motivo_consulta"></motivoConsultaShow>
      </div>
      <div class="py-2">
        <examenFisicoShow :idHistoria="idHistoria" :examenFisico="resumen.examen_fisico"></examenFisicoShow>
      </div>
      <div class="py-2">
        <exploracionFisicaShow :idHistoria="idHistoria" :data="resumen.exploracion_fisica"></exploracionFisicaShow>
      </div>
      <div class="py-2">
      <div >
        <div class="card-header bg-light "><span><i class="fa fa-user-md"> </i> Graficas de Evaluación Nutricional</span> </div>
        <div class="card-body">
          <graficas-component :idHistoria="idHistoria" :usuario="usuario"></graficas-component>
        </div>
      </div>
    </div>
      <div class="py-2" v-if="resumen && resumen.ordenes">
        <ordenClinicaShow :idHistoria="idHistoria" :data="resumen.ordenes"></ordenClinicaShow>
      </div>
      <div class="py-2" v-if="resumen && resumen.formulas">
        <formulaMedicaShow :idHistoria="idHistoria" :data="resumen.formulas"></formulaMedicaShow>
      </div>
      <div class="py-2" v-if="resumen && resumen.impresion_diagnostica">
        <impresionDxShow :idHistoria="idHistoria" :data="resumen.impresion_diagnostica"></impresionDxShow>
      </div>
      <div class="py-2" v-if="resumen && resumen.urgencia_revision">
        <revisionSistemasShow :idHistoria="idHistoria" :data="resumen.urgencia_revision"></revisionSistemasShow>
      </div>
      <div class="py-2" v-if="resumen && resumen.vale">
        <valeShow :idHistoria="idHistoria"
         :data_riesgos="resumen.vale" 
         :condiciones_estructurales="resumen.condiciones_estructurales"
         :items_valoracion="resumen.items_valoracion"></valeShow>
      </div>
      <div class="py-2" v-if="resumen && resumen.usuario">
        <vacunasShow :idHistoria="idHistoria" :data="resumen.usuario"></vacunasShow>
      </div>
      <div class="py-2" v-if="resumen && resumen.aiepi">
        <aiepiShow :idHistoria="idHistoria" :data="resumen.aiepi"></aiepiShow>
      </div>
      <div class="py-2" v-if="resumen && resumen.escala_abreviada">
        <indexEvaluacionShow :idHistoria="idHistoria" :data="resumen.escala_abreviada" :idUsuario="idUsuario"></indexEvaluacionShow>
      </div>
      <div class="py-2" v-if="resumen && resumen.intervenciones">
        <intervencionesShow :idHistoria="idHistoria" :data="resumen.intervenciones"></intervencionesShow>
      </div>
      <div class="py-2" v-if="resumen && resumen.recomendaciones">
        <recomendacionesShow :idHistoria="idHistoria" :data="resumen.recomendaciones"></recomendacionesShow>
      </div>
    </div>
  </main>
</template>

<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../../base/impresionDX/impresionDxShow";
import exploracionFisicaShow from "../../base/examenFisico/exploracionFisicaShow";
import revisionSistemasShow from "../../base/baseUrgencia/revisionShow";
import historiaClinicaService from "../../../../services/historiaClinicaService";
import valeShow from "../../base/baseVale/valeShow";
import vacunasShow from "../../base/baseVacunas/index/vacunasShow";
import intervencionesShow from "../../base/intervenciones/intervencionesShow";
import recomendacionesShow from "../../base/recomendaciones/recomendacionesShow";
import aiepiShow from "../../base/baseAiepi/index/aiepiShow";
import indexEvaluacionShow from "../../base/baseEscalaAbreviadaDesarrollo/index/indexEvaluacionShow";
import graficasComponent from '../graficas';

export default {
  props: ["idHistoria", "idUsuario", "usuario"],
  components: {
    motivoConsultaShow,
    examenFisicoShow,
    ordenClinicaShow,
    formulaMedicaShow,
    impresionDxShow,
    exploracionFisicaShow,
    revisionSistemasShow,
    aiepiShow,
    indexEvaluacionShow,
    valeShow,
    vacunasShow,
    graficasComponent,
    intervencionesShow,
    recomendacionesShow
  },
  data() {
    return {
      resumen: {},
      isLoading: true
    };
  },

  async created() {
    this.isLoading = true;
    const response = await historiaClinicaService.resumenHc(this.idHistoria);
    this.resumen = response.data;
    this.isLoading = false;

  },
};
</script>

<style scoped>
.resumen-container {
  height: 300px;
  overflow-y: scroll;
}
</style>
