<template>
    <main>
        <h6 class="text-info">Condiciones Estructurales</h6>
        <table class=" table table-striped table-sm table-bordered font-size-12">
            <tr>
                <th>Rango</th>
                <th>Condiciones Estructurales</th>
                <th>Presencia</th>
                <th>Integridad</th>
            </tr>
            <tr v-for="item in data_condiciones_estructurales" :key="item.id">
                <td>TODAS LAS EDADES</td>
                <td>{{item.condicion_estructural.condiciones_estructurales}}</td>
                <td>{{item.presencia}}</td>
                <td>{{item.integridad}}</td>
            </tr>
        </table>
    </main>
</template>
<script>
export default {
    props: ["idHistoria", "data_condiciones_estructurales"],
}
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>