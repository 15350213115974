<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-list"> </i> Aiepi</span> </div>
            <div class="card-body">
                <div class="table-responsive" v-for="sec in data" :key="sec.id">
                    <div>
                        <span class="text-info">Seccion {{sec.seccion.nombre}}</span>
                        <table class="table table-striped table-bordered">
                            <tr>
                                <th>Item</th>
                                <th>Respuesta</th>
                            </tr>
                            <tr v-for="ite in sec.respuestas" :key="ite.id">
                                <td>
                                    {{ite.item.nombre}}
                                </td>
                                <td>
                                    {{ite.respuesta}}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <ul>
                                        <li v-for="dx in sec.diagnosticos" :key="`dx_${dx.id}`">
                                            {{dx.diagnostico.diagnostico}}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props: ['idHistoria','data']
}
</script>

