<template>
    <main>
        <h6 class="text-info">Items Valoracion</h6>
        <table class=" table table-striped table-sm table-bordered font-size-12">
            <tr>
                <th>Rango</th>
                <th>Reporte de padre</th>
                <th>Observacion Directa</th>
                <th>Respuesta</th>
                <th>Observacion Directa</th>

            </tr>
            <tr v-for="item in items_valoracion" :key="item.id">
                <td>{{item.item_valoracion_vale.edad_inicio}} {{item.item_valoracion_vale.tipo_edad_inicio}} a {{item.item_valoracion_vale.edad_final}} {{item.item_valoracion_vale.tipo_edad_final}}</td>
                <td><b>{{item.item_valoracion_vale.tipo_item}}:</b> {{item.item_valoracion_vale.reporte_padres}}</td>
                <td><b>{{item.item_valoracion_vale.tipo_item}}:</b> {{item.item_valoracion_vale.observacion_directa}}</td>
                <td class="w-10">{{item.respuesta}}</td>
                <td class="w-10">{{item.item_observacion_directa}}</td>
            </tr>
        </table>
    </main>
</template>
<script>
export default {
    props: ["idHistoria", "items_valoracion"],
}
</script>
<style scoped>

.font-size-12 {
  font-size: 12px;
}

.w-10{
    width: 10%;
}

</style>