<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-diagnoses"> </i> Exploracion Fisica</span> </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-4" v-for="item in data" :key="item.id">
                        <h6> {{ item.parte_fisica.nombre }} : {{ item.detalle }} </h6>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props: ['idHistoria', 'finalizada','data']
}
</script>