<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-pills"></i> Vale</span> </div>
            <div class="card-body">
                <div class="table-responsive">
                    <riesgosGeneralesShow :idHistoria="idHistoria" :data_riesgos="data_riesgos" ></riesgosGeneralesShow>
                </div>
                <div class="table-responsive">
                    <condicionesEstructuralesShow :idHistoria="idHistoria" :data_condiciones_estructurales="condiciones_estructurales" ></condicionesEstructuralesShow>
                </div>
                <div class="table-responsive">
                    <itemsValoracionShow :idHistoria="idHistoria" :items_valoracion="items_valoracion"></itemsValoracionShow>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import riesgosGeneralesShow from "./valeShow/riesgosGeneralesShow";
import condicionesEstructuralesShow from "./valeShow/condicionesEstructuralesShow";
import itemsValoracionShow from "./valeShow/itemsValoracionShow";

export default {
  props: ["idHistoria",'data_riesgos','condiciones_estructurales','items_valoracion'],
  components : {riesgosGeneralesShow,condicionesEstructuralesShow,itemsValoracionShow},
}
</script>