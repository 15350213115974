<template>
    <main>
        <div class="container-xl px-4">
            <div class="card shadow-none mt-2">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered">
                            <tr>
                                <th>Pregunta</th>
                                <th>Opciones</th>
                            </tr>
                            <tr v-for="pre in preguntas" :key="pre.id">
                                <td>{{ pre.descripcion }}</td>
                                <td>
                                    <select class="form-control" v-model="pre.respuesta">
                                        <option v-for="opc in pre.opciones" :key="`${opc.id}_opc`" :value="opc.valor">
                                            {{ opc.opcion }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <div class="form-group">
                            <label class="form-label">Puntaje Total</label>
                            <input type="number" class="form-control" readonly v-model="puntaje_total">
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <span class="d-block small">Ultima Valoración :
                    <span v-if="Object.keys(apgar).length">{{ apgar.fecha | formatDate }}</span>
                    <span v-else class="text-danger">Sin datos...</span>
                </span>
                <span class="d-block small">Puntaje Total :
                    <span v-if="Object.keys(apgar).length">{{ apgar.puntaje_total }}</span>
                    <span v-else class="text-danger">Sin datos...</span>
                </span>
                <span :class="clasificacion_apgar.class">{{ clasificacion_apgar.valoracion }}</span>
                
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <button class="btn btn-success" @click="guardar()">
                        <i class="fas fa-save fa-fw text-white-50"></i>
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import preguntasApgarFamiliarService from '../../../../services/preguntasApgarFamiliarService';
import valoracionApgarFamiliarService from '../../../../services/valoracionApgarFamiliarService';
import Swal from "sweetalert2";
import {isEmpty} from 'lodash';
import dayjs from 'dayjs';
export default {
    props: ['usuario', 'finalizada'],
    data() {
        return {
            preguntas: [],
            apgar: {},
        };
    },
    watch: {
        usuario: async function () {
            const response = await valoracionApgarFamiliarService.showLast(this.usuario.id);
            this.apgar = response.data;
        }
    },
    methods: {
        async cargarPreguntas() {
            const response = await preguntasApgarFamiliarService.index();
            this.preguntas = response.data.map(p => {
                return {
                    ...p,
                    respuesta: '',

                }
            });
        },
        async cargarRespuestas(){
            const response = await valoracionApgarFamiliarService.showLast(this.usuario.id);
            this.apgar = response.data;
        },
        async guardar() {
            try {

                let valoracion_apgar = {
                    puntaje_total: this.puntaje_total,
                    id_usuario: this.usuario.id,
                    respuestas: this.preguntas,
                };

                this.LoaderSpinnerShow();

                await valoracionApgarFamiliarService.store(valoracion_apgar);

                this.LoaderSpinnerHide();
                await this.cargarRespuestas();
                
                await Swal.fire("Datos guardados con exito", "", "success");
                

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Swal.fire("Ups!", "ha ocurrido un error al procesar la solicitud", "error");
            }
        },
    },
    async created() {
        await this.cargarPreguntas();
        await this.cargarRespuestas();
    },
    computed: {
        puntaje_total: function () {
            return this.preguntas.reduce((t, x) => {
                return t + (x.respuesta !== '' ? Number(x.respuesta) : 0);
            }, 0);
        },
        edad: function () {
            if (isEmpty(this.usuario)) return 0;
            return dayjs().diff(this.usuario.fecha_nacimiento, 'year');
        },
        clasificacion_apgar() {

            const clasficicacion_ninos = [
                {
                    inicio: 7,
                    fin: 10,
                    valoracion: 'Funcionalidad Normal',
                    class: 'badge badge-success'
                },
                {
                    inicio: 4,
                    fin: 6,
                    valoracion: 'Disfunción Moderada',
                    class: 'badge badge-warning'
                },
                {
                    inicio: 0,
                    fin: 3,
                    valoracion: 'Disfunción Grave',
                    class: 'badge badge-danger'
                }
            ];

            if (this.edad <= 18) {
                const clasificacion = clasficicacion_ninos.find(x => this.apgar.puntaje_total >= x.inicio && this.apgar.puntaje_total <= x.fin);
                if (clasificacion) return clasificacion
            }

            return {
                inicio: 0,
                fin: 0,
                valoracion: 'Cargando clasificacion',
                class: 'span span-default text-danger'
            };
        },
        tiempo_ultimo_apgar() {
            if (isEmpty(this.apgar)) return 0;
            return dayjs().diff(this.apgar.fecha, 'year');
        }

    }
}
</script>